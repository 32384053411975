<template>
  <div class="mobile">
    <div class="header" :class="[scrollTop > 0 ? 'active' : '']">
      <img src="../../assets/img/mobile/2024/logo.png" alt="" />
      <Downloadbtn class="downloadbtn" type="mobile" />
    </div>
    <div class="part-one">
      <img class="banner" src="../../assets/img/mobile/2024/banner.jpg" />
    </div>
    <div class="part-two">
      <img class="title" src="../../assets/img/mobile/2024/bg_1.png" alt="" />
      <img class="ball1" src="../../assets/img/mobile/2023/ball1.png" alt="" />

      <transition name="fade">
        <div class="video-play" v-show="gifList[0].showVideo">
          <img :src="gifList[0].path" alt="" />
        </div>
      </transition>
      <transition name="fade">
        <div class="video-play" v-show="gifList[1].showVideo">
          <img :src="gifList[1].path" alt="" />
        </div>
      </transition>
      <transition name="fade">
        <div class="video-play" v-show="gifList[2].showVideo">
          <img :src="gifList[2].path" alt="" />
        </div>
      </transition>
      <img class="picture" src="../../assets/img/mobile/2023/phone.png" alt="" />
      <img class="ornament" src="../../assets/img/mobile/2024/ornament.png" alt="" />

      <div class="img-List">
        <div :class="item.showArrow ? 'img-item-choose' : 'img-item'" v-for="(item, index) in imgList" :key="index" @click="chooseThis(item, index)">
          <div class="img-overflow">
            <img :src="item.path" alt="" />
            <div v-show="!item.showArrow" class="shade"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="part-three">
      <img class="title" src="../../assets/img/mobile/2024/t.png" alt="" />
      <img class="double" src="../../assets/img/mobile/2024/t_1.png" alt="" />
    </div>
    <div class="part-two part-fourth">
      <img class="title" src="../../assets/img/mobile/2024/icon.png" alt="" />
      <img class="ball1" src="../../assets/img/mobile/2023/ball1.png" alt="" />

      <transition name="fade">
        <div class="video-play" v-show="iconIdx == 0">
          <img :src="iconList[0].path" alt="" />
        </div>
      </transition>
      <transition name="fade">
        <div class="video-play" v-show="iconIdx == 1">
          <img :src="iconList[1].path" alt="" />
        </div>
      </transition>
      <transition name="fade">
        <div class="video-play" v-show="iconIdx == 2">
          <img :src="iconList[2].path" alt="" />
        </div>
      </transition>
      <img class="picture" src="../../assets/img/mobile/2024/p.png" alt="" />
      <div class="img-List">
        <div :class="item.id == iconIdx ? 'img-item-choose' : 'img-item'" v-for="(item, index) in iconList" :key="index" @click="chooseIcon(item, index)">
          <div class="img-overflow">
            <img :src="item.path" alt="" />
            <div v-show="item.id != iconIdx" class="shade"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="part-five">
      <img class="title" src="../../assets/img/mobile/2024/f_t.png" alt="" />
      <img class="double" src="../../assets/img/mobile/2024/f_d.png" alt="" />
      <img class="ball1" src="../../assets/img/mobile/2023/ball1.png" alt="" />
    </div>
    <Downmost class="downmost" :type="go"></Downmost>

    <!-- <transition name="fade">
      <div class="video-box" v-show="showPromotional">
        <div class="video-shade" @click="closeVideo"></div>
        <video id="my-video" class="video-player" src="../../assets/img/mobile/2023/pc/promotional.mp4" controls></video>
      </div>
    </transition> -->
  </div>
</template>

<script>
import Downloadbtn from "./downloadbtn2.vue";
import Header from "./header.vue";
import Downmost from "./downmost.vue";
import { init } from "./utils";
export default {
  components: { Downloadbtn, Header, Downmost },
  data() {
    return {
      scrollTop: 0,
      imgList: [
        {
          showArrow: true,
          path: require(`../../assets/img/mobile/2023/pc/1.jpg`),
        },
        {
          showArrow: false,
          path: require(`../../assets/img/mobile/2023/pc/2.jpg`),
        },
        {
          showArrow: false,
          path: require(`../../assets/img/mobile/2023/pc/3.jpg`),
        },
      ],
      gifList: [
        {
          showVideo: true,
          path: "http://pcsource.upupoo.com/official_website/gif/1.webp",
        },
        {
          showVideo: false,
          path: "http://pcsource.upupoo.com/official_website/gif/2.webp",
        },
        {
          showVideo: false,
          path: "http://pcsource.upupoo.com/official_website/gif/3.webp",
        },
      ],
      go: {
        name: "关于我们",
        link: "./about2",
      },
      iconIdx: 0,
      iconList: [
        {
          id: 0,
          path: require("../../assets/img/mobile/2024/icon_3.jpg"),
        },
        {
          id: 1,
          path: require("../../assets/img/mobile/2024/icon_2.jpg"),
        },
        {
          id: 2,
          path: require("../../assets/img/mobile/2024/icon_1.jpg"),
        },
      ],
      showPromotional: false, // 播放宣传视频
    };
  },
  methods: {
    // 播放视频
    playVideo() {
      // console.log("play video");
      this.showPromotional = true;
      let v = document.getElementById("my-video");
      v.play();
      window.addEventListener("touchmove", this.handleStopWheel, {
        passive: false,
      });
    },
    chooseIcon(item) {
      this.iconIdx = item.id;
    },
    closeVideo() {
      this.showPromotional = false;
      let v = document.getElementById("my-video");
      v.pause();
      v.currentTime = 0;
      window.removeEventListener("touchmove", this.handleStopWheel);
    },
    // 禁止手指头划的滚动，滚轮可以但无伤大雅 ~
    handleStopWheel(e) {
      e.preventDefault();
    },
    handleScroll(top) {
      // 获取滚动位置
      this.scrollTop = top;
      // 根据滚动位置进行逻辑处理
      //   console.log("Scroll Position:", this.scrollTop);
    },
    // 选择某个图片
    chooseThis(item, index) {
      for (let i = 0; i < this.imgList.length; i++) {
        this.imgList[i].showArrow = false;
        this.gifList[i].showVideo = false;
      }
      item.showArrow = true;
      this.gifList[index].showVideo = true;
    },
    getRem() {
      (function (doc, win) {
        let docEl = doc.documentElement,
          resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
          recalc = function () {
            let clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (clientWidth >= 750) {
              docEl.style.fontSize = "100px";
            } else {
              docEl.style.fontSize = 100 * (clientWidth / 750) + "px";
            }
          };

        recalc();
        if (!doc.addEventListener) return;
        win.addEventListener(resizeEvt, recalc, false);
        doc.addEventListener("DOMContentLoaded", recalc, false);
      })(document, window);
    },
  },
  mounted() {
    init();
    this.getRem();
  },
  created() {
    this.$bus.$off("handleElScroll");
    this.$bus.$on("handleElScroll", (top) => {
      this.handleScroll(top);
    });
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  position: relative;
  width: 100%;
  overflow-x: hidden;

  .header {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 1.26rem;
    z-index: 9;
    &.active {
      background: rgba(21, 18, 18, 0.68);
    }

    img {
      position: absolute;
      left: 0.24rem;
      width: 2.88rem;
      top: 0.32rem;
    }
    .downloadbtn {
      position: absolute;
      right: 0.24rem;
      top: 0.28rem;
    }
  }

  .part-one {
    position: relative;

    width: 100%;

    .banner {
      width: 100%;
    }
  }

  .part-two {
    --width-picture: 57.87%;
    position: relative;
    margin-top: 0.24rem;
    width: 100%;
    .title {
      position: relative;
      transform: translate(-50%, 0);
      left: 50%;
      width: 4.93rem;
      z-index: 1;
    }
    .picture {
      position: absolute;
      margin-top: 0.47rem;
      margin-left: 10.4%;
      width: 4.34rem; // *1.78是高
    }

    .ornament {
      position: absolute;
      margin-left: 1.07%;
      width: 19.33%;
    }

    .ball1 {
      position: absolute;
      bottom: -9%;
      left: 0;
      width: 36.93%;
    }

    .video-play {
      position: absolute;
      margin-top: calc(0.47rem + 2%);
      left: 12%;
      width: 55%;

      video {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .img-List {
      position: relative;
      width: 21.2%;
      left: 69.7%;
      margin-top: 0.47rem;
      overflow: hidden;

      .img-item {
        box-sizing: border-box;
        position: relative;
        width: 1.36rem;
        height: 2.47rem;
        margin-left: 7.57%;
        border: 0.03rem solid rgba(178, 201, 255, 1);
        border-radius: 0.14rem;
        margin-bottom: 0.14rem;
        overflow: hidden;
        transition: 0.2s;

        .img-overflow {
          box-sizing: border-box;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          border-radius: 0.14rem;
          overflow: hidden;

          img {
            user-select: none;
            pointer-events: none;
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .shade {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: #000000;
            opacity: 0.6;
            transition: 0.2s;
          }
        }
      }

      .img-item-choose {
        box-sizing: border-box;
        position: relative;
        width: 1.36rem;
        height: 2.47rem;
        margin-left: 7.57%;
        border: 0.03rem solid rgba(46, 52, 69, 1);
        border-radius: 0.14rem;
        margin-bottom: 0.14rem;
        transition: 0.2s;

        &::after {
          content: "";
          position: absolute;
          transform: translate(0, -50%);
          top: 50%;
          left: calc(-8% - 0.03rem);
          width: 8%;
          height: 7.3%;
          background: #2e3445;
          clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 50%);
        }

        .img-overflow {
          box-sizing: border-box;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          border-radius: 0.14rem;
          overflow: hidden;

          img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            // min-height: 124px;
          }
        }
      }
    }
  }

  .part-three {
    position: relative;
    margin-top: 0.7rem;
    .title {
      position: relative;
      transform: translate(-50%, 0);
      left: 50%;
      width: 3.12rem;
      z-index: 1;
    }
    .double {
      margin-top: 0.47rem;
      position: relative;
      width: 100%;
    }
  }
  .part-fourth {
    margin-top: 0.7rem;
    .title {
      width: 3.02rem;
    }
    .ball1 {
      bottom: 46%;
      left: 0;
      width: 25%;
    }
    .picture {
      width: 4.34rem;
      height: 6.39rem;
    }
    .img-List {
      .img-item-choose {
        width: 1.36rem;
        height: 2.04rem;
        .img-overflow {
          border-radius: 0.14rem;
        }
      }
      .img-item {
        width: 1.36rem;
        height: 2.04rem;
      }
    }
  }
  .part-five {
    position: relative;
    .title {
      z-index: 2;
      width: 4.53rem;
      margin-top: 0.7rem;
      position: relative;
      transform: translate(-50%, 0);
      left: 50%;
    }
    .double {
      position: relative;
      z-index: 2;
      margin-top: 0.4rem;
      position: relative;
      width: 6.52rem;
      margin-bottom: 0.46rem;
      transform: translate(-50%, 0);
      left: 50%;
    }
    .ball1 {
      position: absolute;
      bottom: 16%;
      right: 0;
      width: 25%;
      transform: rotate(180deg);
    }
  }

  .downmost {
    position: relative;
    // bottom: 80px;
    width: 100%;
  }

  .downloadbg {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 71px;
    // background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(0deg, #ffffff, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.5) 75%, rgba(255, 255, 255, 0) 100%);
    z-index: 9;
  }
}

.video-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;

  .video-shade {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000005e;
  }

  .video-player {
    position: absolute;
    max-width: 90%;
    max-height: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}
</style>
