<template>
  <div>
    <a :class="['download3', type]" title="点击下载" :href="apkurl">
      <i class="after"></i>
      点击下载
    </a>
  </div>
</template>
<script>
import { isMobile } from "./utils.js";
export default {
  props: ["type"],
  data() {
    return {
      isMobile,
      apkurl: null,
    };
  },
  methods: {
    download() {
      //this.$bus.$emit("downLoadPc");
    },
  },
  mounted() {
    this.$bus.$on("apkurl", (apkurl) => {
      this.apkurl = apkurl;
    });
  },
};
</script>
<style scoped lang="scss">
.download3 {
  width: 2.18rem;
  height: 0.72rem;
  background: #ffffff;
  border-radius: 0.16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 0.28rem;
  color: #158cf2;
  position: relative;
  .after {
    background: url("../../assets/img/mobile/2024/down.png") no-repeat;
    background-size: 100% 100%;
    width: 0.34rem;
    height: 0.34rem;
    margin-right: 0.1rem;
  }
}
</style>
