<template>
  <div class="container" v-if="!isMobile">
    <div class="header" :class="ani1 ? 'ani1' : ''" ref="ref1">
      <img class="ball1" src="../../assets/img/mobile/2023/pc/ball1.png" alt="" />
      <img class="logo" src="../../assets/img/mobile/2023/pc/logo.png" alt="" />
      <img class="title1" src="../../assets/img/mobile/2023/pc/title1.png" alt="" />
      <img class="mascot" src="../../assets/img/mobile/2023/pc/mascot.png" alt="" />
      <img class="ball2" src="../../assets/img/mobile/2023/pc/ball2.png" alt="" />
      <img class="flotage1" src="../../assets/img/mobile/2023/pc/flotage1.png" alt="" />
      <img class="flotage2" src="../../assets/img/mobile/2023/pc/flotage2.png" alt="" />
      <!-- <img class="flotage3" src="../../assets/img/mobile/2023/pc/flotage3.png" alt="" /> -->
      <!-- <div class="play" @click="playVideo" @mouseenter="monplay = true" @mouseleave="monplay = false">
        <img src="../../assets/img/mobile/2023/pc/play.png" alt="" />
        <transition name="fade">
          <img v-show="monplay" src="../../assets/img/mobile/2023/pc/play_hover.png" alt="" />
        </transition>
      </div> -->
      <div class="btn-module">
        <div class="qr-code">
          <img :src="qrcode" alt="" />
        </div>
        <div class="btn">
          <Downloadbtn class="download-header" type="large" />
          <a class="go-PC" href="/" title="前往电脑端">
            <span>前往电脑端</span>
            <img src="../../assets/img/mobile/2023/pc/btn_normal.png" alt="" />
          </a>
        </div>
      </div>
    </div>
    <div class="part-all">
      <div class="outof-part-one">
        <div class="part-one" :class="ani2 ? 'ani1' : ''" ref="ref2">
          <img class="picture" src="../../assets/img/mobile/2023/pc/mobile-phone.png" alt="" />
          <img class="flotage4" src="../../assets/img/mobile/2023/pc/flotage4.png" alt="" />
          <img class="title-one" src="../../assets/img/mobile/2023/pc/title2.png" alt="" />
          <!-- <video muted autoplay="autoplay" loop="loop" class="gif-play" :src="gif"></video> -->
          <transition name="fade">
            <div class="video-play" v-show="gifList[0].showVideo">
              <video muted autoplay="autoplay" loop="loop" :src="gifList[0].path"></video>
            </div>
          </transition>
          <transition name="fade">
            <div class="video-play" v-show="gifList[1].showVideo">
              <video muted autoplay="autoplay" loop="loop" :src="gifList[1].path"></video>
            </div>
          </transition>
          <transition name="fade">
            <div class="video-play" v-show="gifList[2].showVideo">
              <video muted autoplay="autoplay" loop="loop" :src="gifList[2].path"></video>
            </div>
          </transition>
          <div class="img-List">
            <div :class="item.showArrow ? 'img-item-choose' : 'img-item'" v-for="(item, index) in imgList" :key="index" @click="chooseThis(item, index)">
              <div class="img-overflow">
                <img :src="item.path" alt="" />
                <div v-show="!item.showArrow" class="shade"></div>
              </div>
            </div>
          </div>
          <Downloadbtn class="download-one" type="medium" />
        </div>
      </div>
      <div class="outof-part-two">
        <div class="part-two" :class="ani3 ? 'ani1' : ''" ref="ref3">
          <img class="title-two" src="../../assets/img/mobile/2023/pc/title3.png" alt="" />
          <img class="double" src="../../assets/img/mobile/2023/pc/d3.png" alt="" />
          <!-- <img class="flotage5" src="../../assets/img/mobile/2023/pc/flotage5.png" alt="" /> -->
          <Downloadbtn class="download-two" type="medium" />
        </div>
      </div>
      <div class="outof-part-three">
        <div class="part-three" :class="ani4 ? 'ani1' : ''" ref="ref4">
          <img class="picture" src="../../assets/img/mobile/2023/pc/mobile-phone.png" alt="" />
          <img class="flotage4" src="../../assets/img/mobile/2024/iconPc.png" alt="" />
          <img class="title-three" src="../../assets/img/mobile/2023/pc/title4.png" alt="" />
          <transition name="fade">
            <div class="video-play" v-show="iconIdx == 0">
              <img :src="iconList[0].path" alt="" />
            </div>
          </transition>
          <transition name="fade">
            <div class="video-play" v-show="iconIdx == 1">
              <img :src="iconList[1].path" alt="" />
            </div>
          </transition>
          <transition name="fade">
            <div class="video-play" v-show="iconIdx == 2">
              <img :src="iconList[2].path" alt="" />
            </div>
          </transition>
          <div class="img-List">
            <div :class="item.id == iconIdx ? 'img-item-choose' : 'img-item'" v-for="(item, index) in iconList" :key="index" @click="chooseIcon(item, index)">
              <div class="img-overflow">
                <img :src="item.path" alt="" />
                <div v-show="item.id != iconIdx" class="shade"></div>
              </div>
            </div>
          </div>
          <Downloadbtn class="download-three" type="medium" />
        </div>
      </div>
      <div class="outof-part-five">
        <div class="part-five" :class="ani5 ? 'ani1' : ''" ref="ref5">
          <img class="title-five" src="../../assets/img/mobile/2023/pc/title5.png" alt="" />
          <img class="double" src="../../assets/img/mobile/2023/pc/double.png" alt="" />
          <img class="flotage5" src="../../assets/img/mobile/2023/pc/flotage5.png" alt="" />
          <Downloadbtn class="download-five" type="medium" />
        </div>
      </div>
    </div>
    <!-- aspectRatio="9:16" -->
    <videoModel :dialogVisible="dialogVisible" v-if="dialogVisible" :poster="poster" :videoUrl="videoUrl" :typeVideo="typeVideo" :ifreamUrl="ifreamUrl" @closeModel="closeModel"></videoModel>
  </div>
  <Index2 v-else />
</template>

<script>
import Downloadbtn from "./downloadbtn.vue";
import Index2 from "./index2.vue";
import { isMobile } from "./utils";
import axios from "axios";
import videoModel from "@/components/videoModel";

export default {
  components: { Downloadbtn, Index2, videoModel },
  data() {
    return {
      isMobile,
      imgList: [
        {
          showArrow: true,
          path: require(`../../assets/img/mobile/2023/pc/1.jpg`),
        },
        {
          showArrow: false,
          path: require(`../../assets/img/mobile/2023/pc/2.jpg`),
        },
        {
          showArrow: false,
          path: require(`../../assets/img/mobile/2023/pc/3.jpg`),
        },
      ],
      gifList: [
        {
          showVideo: true,
          path: require(`../../assets/img/mobile/2023/pc/1.mp4`),
        },
        {
          showVideo: false,
          path: require(`../../assets/img/mobile/2023/pc/2.mp4`),
        },
        {
          showVideo: false,
          path: require(`../../assets/img/mobile/2023/pc/3.mp4`),
        },
      ],
      ani1: true,
      ani2: false,
      ani3: false,
      ani4: false,
      ani5: false,
      qrcode: null,
      apkurl: null,
      dialogVisible: false,
      videoUrl: "",
      typeVideo: "1", //种类
      poster: "", //封面
      ifreamUrl: "",
      monplay: false,
      iconIdx: 0,
      iconList: [
        {
          id: 0,
          path: require("../../assets/img/mobile/2024/iconPc_3.png"),
        },
        {
          id: 1,
          path: require("../../assets/img/mobile/2024/iconPc_2.png"),
        },
        {
          id: 2,
          path: require("../../assets/img/mobile/2024/iconPc_1.png"),
        },
      ],
    };
  },
  created() {
    // !isMobile && this.getQrcode();
    !isMobile && this.getQrcode();
    this.getApkurl();
  },
  methods: {
    // 选择某个图片
    chooseThis(item, index) {
      for (let i = 0; i < this.imgList.length; i++) {
        this.imgList[i].showArrow = false;
        this.gifList[i].showVideo = false;
      }
      item.showArrow = true;
      this.gifList[index].showVideo = true;
      // this.gif = this.gifList[index]
    },
    chooseIcon(item) {
      this.iconIdx = item.id;
    },
    cantSee: (el, fn) => {
      if (el) {
        let rect = el.getBoundingClientRect();
        fn(rect.top >= (window.innerHeight || document.documentElement.clientHeight));
      }
    },
    playVideo() {
      let data = {
        url: require("../../assets/img/mobile/2023/pc/promotional.mp4"),
        // url: "../../assets/img/mobile/2023/pc/promotional.mp4",
        poster: "",
        typeVideo: 1,
      };
      // console.log("showVideoModel");
      this.videoUrl = data.url || "";
      this.poster = data.poster;
      this.typeVideo = data.typeVideo;
      this.ifreamUrl = data.ifreamUrl || "";
      this.dialogVisible = true;
    },
    //关闭视频弹窗
    closeModel() {
      this.videoUrl = "";
      this.poster = "";
      this.dialogVisible = false;
    },
    // async getQrcode() {
    //     let host = 'https://website.upupoo.com/',
    //        re = await axios.all([axios.get(`${host}official/qr_code/qrcode`),axios.get(`${host}official/qr_code/app/download`)]);

    //     this.qrcode = `data:image/png;base64,${re[0].data.data.qr_code}`;
    //     this.apkurl = `${re[1].data.data.qr_code}`;

    //     this.$bus.$emit('qrCode', this.qrcode)
    //     this.$bus.$emit('apkurl', this.apkurl)
    // }

    async getQrcode() {
      let res = await axios.get("https://website.upupoo.com/official/qr_code/qrcode");
      // console.log('res:',res);
      this.qrcode = `data:image/png;base64,${res.data.data.qr_code}`;
      this.$bus.$emit("qrCode", this.qrcode);
    },
    async getApkurl() {
      let res = await axios.get("https://website.upupoo.com/official/qr_code/app/download");
      // console.log('res', res);
      this.apkurl = res.data.data.qr_code;
      this.$bus.$emit("apkurl", this.apkurl);
    },
  },
  mounted() {
    let scrollbarEl = this.$scrollbar.wrap;
    scrollbarEl.addEventListener("scroll", () => {
      let dom1 = this.$refs.ref1;
      let dom2 = this.$refs.ref2;
      let dom3 = this.$refs.ref3;
      let dom4 = this.$refs.ref4;
      let dom5 = this.$refs.ref5;
      //   let dom4 = this.$refs.ref4;
      this.cantSee(dom1, (re) => {
        this.ani1 = !re;
      });
      this.cantSee(dom2, (re) => {
        this.ani2 = !re;
      });
      this.cantSee(dom3, (re) => {
        this.ani3 = !re;
      });
      this.cantSee(dom4, (re) => {
        this.ani4 = !re;
      });
      this.cantSee(dom5, (re) => {
        this.ani5 = !re;
      });

      //   this.cantSee(dom4, (re) => {
      //     this.ani4 = !re;
      //   });
    });
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  box-sizing: border-box;
  min-width: 1300px;
  overflow: hidden;

  // /deep/ .el-dialog {
  //     width: 432px;
  //     margin-top: 5% !important;
  // }

  .header {
    position: relative;
    transform: translate(-50%, 0);
    left: 50%;
    width: 1920px; // 用这个缩放页面后居中
    // width: 100%; // 这个缩放后靠左
    height: 866px;
    background: linear-gradient(90deg, #eef9ff 0%, rgba(249, 253, 255, 0) 100%);

    .btn-module {
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: space-between;
      top: 495px;
      left: 387px;
      width: 460px;
      height: 198px;
      padding: 16px 18px;
      background: rgba(192, 227, 254, 0.28);
      // opacity: 0.28;
      border-radius: 16px;

      .qr-code {
        box-sizing: border-box;
        width: 166px;
        height: 166px;
        // background: #000000;
        border: 2px solid #2197f9;
        border-radius: 14px;
        margin: 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .btn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        // .download-header {}

        .go-PC {
          box-sizing: border-box;
          position: relative;
          // display: flex; // 这用flex居然会导致img错位？！
          // justify-content: center;
          // align-items: center;
          width: 242px;
          height: 57px;
          background: #f9fdff;
          border: 2px solid #a2a5a6;
          border-radius: 16px;
          cursor: pointer;
          // transition: .3s;

          span {
            position: absolute;
            top: 18px;
            left: 60px;
            font-family: Microsoft-YaHei;
            font-size: 18px;
            line-height: 18px;
            font-weight: bold;
            color: rgb(0, 0, 0);
            // transition: .3s;
          }

          img {
            position: absolute;
            top: 18px;
            left: 150px;
            width: 16px;
            height: 16px;
            padding: 1px 1px 1px 8px;
          }

          &:hover {
            border-color: rgba(33, 151, 249, 1);
            // transition: .3s;
          }

          &:hover span {
            color: rgba(33, 151, 249, 1);
            // transition: .3s;
          }

          &:hover img {
            content: url("../../assets/img/mobile/2023/pc/btn_hover.png");
          }
        }
      }
    }

    .ball1 {
      position: absolute;
      top: 0;
      left: 0;
    }

    .logo {
      position: absolute;
      top: 150px;
      left: 452px;
    }

    .title1 {
      position: absolute;
      top: 242px;
      left: 279px;
    }

    .mascot {
      position: absolute;
      top: 70px;
      left: 1140px;
    }

    .ball2 {
      position: absolute;
      top: 581px;
      left: 1780px;
    }

    .flotage1 {
      position: absolute;
      top: 66px;
      left: 1130px;
      width: 130px;
      // transform: translateY(0px);
      animation: fly 2.5s infinite;
      animation-timing-function: ease-in-out;
    }

    .flotage2 {
      position: absolute;
      top: 541px;
      left: 1493px;
      width: 146px;
      animation: fly 2.5s infinite;
      animation-timing-function: ease-in-out;
    }

    .flotage3 {
      position: absolute;
      top: 585px;
      left: 1298px;
      animation: fly 2.5s infinite;
      animation-timing-function: ease-in-out;
    }
    .play {
      position: absolute;
      width: 132px;
      height: 132px;
      top: 370px;
      left: 1279px;
      cursor: pointer;

      img {
        position: absolute;
        // top: 0;
        // left: 0;
      }
    }
  }

  .part-all {
    min-height: 1600px;
    background-color: #fff;
    position: relative;
    transform: translate(-50%, 0);
    left: 50%;
    width: 1920px; // 用这个缩放页面后居中
    // width: 100%; // 这个缩放后靠左

    .outof-part-one {
      position: relative;
      margin-top: 140px; // 为了动画更明显，往上移140 然后下面一堆都往上移了140，甚至楼上的part-all

      .part-one {
        position: relative;
        height: 620px;
        width: 100%;

        img {
          position: absolute;
        }

        .picture {
          top: 4px;
          left: 261px;
          // z-index: 1;
        }

        .flotage4 {
          top: -45px;
          left: 318px;
          animation: fly 2.5s infinite;
          animation-timing-function: ease-in-out;
        }

        .title-one {
          top: 103px;
          left: 1083px;
        }

        .video-play {
          position: absolute;
          top: 23px;
          left: 399px;
          width: 310px;
          // height: 580px;
          z-index: -1;

          video {
            width: 100%;
            height: 100%;
            // object-fit: fill;
          }
        }

        .img-List {
          position: absolute;
          width: 125px;
          height: 616px;
          top: 4px;
          left: 717px;
          overflow: hidden;

          .img-item {
            box-sizing: border-box;
            position: relative;
            width: 105px;
            height: 188px;
            margin-left: 20px;
            // background: #000000;
            // opacity: 0.6;
            border: 3px solid rgba(178, 201, 255, 1);
            border-radius: 14px;
            margin-bottom: 12px;
            cursor: pointer;
            overflow: hidden;
            transition: 0.2s;

            &:hover .img-overflow > .shade {
              opacity: 0;
              transition: 0.1s;
            }

            .img-overflow {
              img {
                width: 105px;
                height: 188px;
              }

              .shade {
                width: 105px;
                height: 188px;
                background: #000000;
                opacity: 0.6;
                border-radius: 10px;
                transition: 0.2s;
              }
            }
          }

          .img-item-choose {
            box-sizing: border-box;
            position: relative;
            width: 105px;
            height: 188px;
            margin-left: 20px;
            // background: #000000;
            // opacity: 0.6;
            border: 3px solid rgba(46, 52, 69, 1);
            border-radius: 14px;
            margin-bottom: 12px;
            cursor: pointer;
            transition: 0.2s;

            &::after {
              content: "";
              position: absolute;
              transform: translate(0, -50%);
              top: 50%;
              left: -10px;
              width: 8px;
              height: 13px;
              background: #2e3445;
              clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 50%);
            }

            .img-overflow {
              position: relative;
              top: 0;
              right: 0;
              width: 99px;
              height: 182px;
              border-radius: 10px;
              overflow: hidden;

              img {
                width: 105px;
                height: 188px;
              }
            }
          }
        }

        .download-one {
          position: absolute;
          top: 366px;
          left: 1366px;
        }
      }
    }

    .outof-part-two {
      // margin-top: 180px; // 为了动画更明显，往上移180

      .part-two {
        position: relative;
        height: 850px;
        width: 100%;

        .title-two {
          position: absolute;
          top: 264px;
          left: 306px;
        }

        .double {
          position: absolute;
          top: 28px;
          left: 994px;
        }

        .flotage5 {
          position: absolute;
          top: 411px;
          left: 937px;
          animation: fly 2.5s infinite;
          animation-timing-function: ease-in-out;
        }

        .download-two {
          position: absolute;
          top: 527px;
          left: 310px;
        }
      }
    }
    .outof-part-three {
      .part-three {
        position: relative;
        height: 620px;
        width: 100%;

        img {
          position: absolute;
        }

        .picture {
          top: 4px;
          left: 261px;
          // z-index: 1;
        }

        .flotage4 {
          top: -45px;
          left: 318px;
          animation: fly 2.5s infinite;
          animation-timing-function: ease-in-out;
        }

        .title-three {
          top: 103px;
          left: 1346px;
        }

        .video-play {
          position: absolute;
          top: 23px;
          left: 399px;
          width: 310px;
          height: 552px;
          z-index: -1;

          video {
            width: 100%;
            height: 100%;
            // object-fit: fill;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .img-List {
          position: absolute;
          width: 125px;
          height: 616px;
          top: 4px;
          left: 717px;
          overflow: hidden;

          .img-item {
            box-sizing: border-box;
            position: relative;
            width: 105px;
            height: 188px;
            margin-left: 20px;
            // background: #000000;
            // opacity: 0.6;
            border: 3px solid rgba(178, 201, 255, 1);
            border-radius: 14px;
            margin-bottom: 12px;
            cursor: pointer;
            overflow: hidden;
            transition: 0.2s;

            &:hover .img-overflow > .shade {
              opacity: 0;
              transition: 0.1s;
            }

            .img-overflow {
              img {
                width: 105px;
                height: 188px;
              }

              .shade {
                width: 105px;
                height: 188px;
                background: #000000;
                opacity: 0.6;
                border-radius: 10px;
                transition: 0.2s;
              }
            }
          }

          .img-item-choose {
            box-sizing: border-box;
            position: relative;
            width: 105px;
            height: 188px;
            margin-left: 20px;
            // background: #000000;
            // opacity: 0.6;
            border: 3px solid rgba(46, 52, 69, 1);
            border-radius: 14px;
            margin-bottom: 12px;
            cursor: pointer;
            transition: 0.2s;

            &::after {
              content: "";
              position: absolute;
              transform: translate(0, -50%);
              top: 50%;
              left: -10px;
              width: 8px;
              height: 13px;
              background: #2e3445;
              clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 50%);
            }

            .img-overflow {
              position: relative;
              top: 0;
              right: 0;
              width: 99px;
              height: 182px;
              border-radius: 10px;
              overflow: hidden;

              img {
                width: 105px;
                height: 188px;
              }
            }
          }
        }

        .download-three {
          position: absolute;
          top: 366px;
          left: 1366px;
        }
      }
    }
    .outof-part-five {
      // margin-top: 180px; // 为了动画更明显，往上移180

      .part-five {
        position: relative;
        height: 710px;
        width: 100%;

        .title-five {
          position: absolute;
          top: 153px;
          left: 306px;
        }

        .double {
          position: absolute;
          top: 28px;
          left: 994px;
        }

        .flotage5 {
          position: absolute;
          top: 411px;
          left: 937px;
          animation: fly 2.5s infinite;
          animation-timing-function: ease-in-out;
        }

        .download-five {
          position: absolute;
          top: 416px;
          left: 310px;
        }
      }
    }
  }
}

@keyframes fly {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.ani1 {
  -webkit-animation: move-up 1.5s;
  -moz-animation: move-up 1.5s;
  animation: move-up 1.5s;
}

@-webkit-keyframes move-up {
  from {
    top: 30px;
    visibility: hidden;
    opacity: 0;
  }

  to {
    top: 0;
    visibility: visible;
    opacity: 1;
  }
}

@-moz-keyframes move-up {
  from {
    top: 30px;
    visibility: hidden;
    opacity: 0;
  }

  to {
    top: 0;
    visibility: visible;
    opacity: 1;
  }
}

@keyframes move-up {
  from {
    top: 30px;
    visibility: hidden;
    opacity: 0;
  }

  to {
    top: 0;
    visibility: visible;
    opacity: 1;
  }
}
</style>
